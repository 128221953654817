@import '/styles/mixins.scss';
@import '/styles/variables.scss';
@import '/styles/responsiveness.scss';

.about {
  @include flex($justify:space-between);
  padding: 2rem 8rem;

  @include tablet() {
    @include flex(column-reverse);
    padding: 2rem;
  }

  @include mobile() {
    @include flex(column-reverse);
    padding: 2rem;
  }

  img {
    width: 70%;
    margin: 0 0 -5rem -15rem;

    @include tablet() {
      margin: 0;
      width: 100%;
    }

    @include mobile() {
      margin: 0;
      width: 100%;
    }
  }

  .aboutMessage {
    color: $white;
    margin-left: -10rem;

    @include tablet() {
      margin: 0;
    }

    @include mobile() {
      margin: 0;
    }

    h2 {
      font-size: 2rem;
    }

    p {
      margin-top: 1rem;
      font-weight: 300;
    }
  }
}