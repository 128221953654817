@import '/styles/mixins.scss';
@import '/styles/variables.scss';

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  padding: 1rem 2rem;
  transition: all 0.5s;

  nav {
    @include flex($justify:space-between);

    @include mobile {
      @include flex($justify:flex-end);
    }

    ul {
      @include flex($justify:space-between);
      width: 24rem;
      margin: 0;
      padding: 0;
      list-style: none;

      @include mobile {
        display: none;
      }

      a {
        text-decoration: none;
        color: $white;
      }
    }

    button {
      border: 0.0625rem solid $white;

      span {
        margin: 0;
      }

      @include mobile {
        position: relative;
        right: -1rem;
      }
    }
  }

  &.headerVisible {
    background-color: rgba(16, 16, 16, 0.4);
    visibility: visible;
  }

  &.headerHidden {
    visibility: hidden;
    transform: translateY(-100%);
  }
}