@import '/styles/mixins.scss';
@import '/styles/variables.scss';

.intro {
  padding: 6rem 8rem 2rem 8rem;

  @include mobile {
    padding: 6rem 2rem 2rem 2rem;
  }

  .introMessage {
    @include flex(column);
    width: 50%;
    text-align: center;
    margin: 0 auto;

    @include tablet {
      width: 75%;
    }

    @include mobile {
      width: 100%;
    }

    h1 {
      @include font(normal, 900, 5rem, 100%, $inter);
      color: $white;
    }

    h3 {
      @include font(normal, 500, 1.5rem);
      color: $white;
    }

    p {
      color: $white;
      margin-top: 2rem;
    }
  }
}