@mixin mobile {
  @media only screen and (max-width: 500px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (min-width: 500px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}