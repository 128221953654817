@import '/styles/mixins.scss';
@import '/styles/variables.scss';

.how {
  .howContent {
    @include flex($justify:space-between);
    padding: 2rem 8rem;

    @include tablet {
      @include flex(column-reverse);
      padding: 2rem;
    }

    @include mobile {
      @include flex(column-reverse);
      padding: 2rem;
    }


    .howMessage {
      width: 45%;
      color: $white;

      h2 {
        font-size: 2rem;
      }

      >p {
        margin-top: 1rem;
        font-weight: 300;
      }

      @include tablet {
        width: 100%;

        h2 {
          margin-top: 2rem;
        }
      }

      @include mobile {
        width: 100%;

        h2 {
          margin-top: 2rem;
        }
      }
    }

    .howSteps {
      @include flex(row, flex-start, space-between);
      margin-top: 2rem;
      gap: 1.5rem;

      @include mobile {
        @include flex(column, flex-start, space-between);
      }

      .step {
        width: 100%;

        h4 {
          @include font(normal, 600, 4.5rem, 100%, $inter);
        }

        p {
          font-weight: 300;
        }
      }
    }

    img {
      width: 40%;

      @include mobile {
        width: 80%;
      }
    }
  }

  .howCards {
    margin-top: 2rem;
    padding: 2rem 8rem;

    @include tablet() {
      padding: 2rem;
    }

    @include mobile() {
      padding: 2rem;
    }

    .cardsContainer {
      @include flex($justify:space-between);

      @include mobile() {
        @include flex(column);
        row-gap: 2rem;
      }

      .card {
        @include container(1.5rem, 1.5rem);
        width: 30%;
        min-height: 18rem;
        box-shadow: 0 0.25rem 3rem rgba(7, 4, 23, 0.5);

        @include mobile() {
          width: 100%;
          min-height: unset;
        }

        img {
          @include container(0.5rem);
          width: 3rem;
          height: 3rem;
          background: $blue;
        }

        h3 {
          font-weight: 500;
          margin-top: 2rem;
          color: #444;
        }

        >p {
          margin-top: 1rem;
          color: #444;
        }
      }
    }

    .annotation {
      width: 60%;
      font-weight: 300;
      color: $white;
      text-align: center;
      margin: 4rem auto;

      @include mobile {
        width: 100%;
      }
    }
  }
}