@import '/styles/mixins.scss';
@import '/styles/variables.scss';

.footer {
  @include flex(column, center, center);
  background-image: url(../../../public/images/landing/footerback.png);
  background-size: 100% 100%;
  background-repeat: unset;
  padding: 4rem;

  @include mobile {
    background-image: url(../../../public/images/landing/footerbackmobile.png);
  }

  .contact {
    @include flex(column, center, center);
    padding: 8rem 0 4rem 0;
    color: $blue;

    h2 {
      @include font(normal, 500, 3rem, 100%, $inter);

      @include mobile() {
        text-align: center;
        font-size: 2rem;
      }
    }

    button {
      margin-top: 2rem;
    }
  }

  .logos {
    @include flex(column);
    width: fit-content;

    .logoContainer {
      @include flex(column, center, center);
      margin-top: 2rem;

      p {
        color: $grey;
        font-weight: 500;
      }

      img {
        width: 10rem;
        margin-top: 0.5rem;
      }

      &:first-child {
        img {
          width: 15rem;
        }
      }
    }
  }
}